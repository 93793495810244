import PropTypes from "prop-types";
import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
// material
import { useTheme } from "@mui/material/styles";
import { Card, CardHeader, Stack, Box, Typography } from "@mui/material";
// utils
import { fNumber } from "../../../utils/formatNumber";
//
import { BaseOptionChart } from "../../charts";

// ----------------------------------------------------------------------

Legend.propTypes = {
  label: PropTypes.string,
  number: PropTypes.number,
};

function Legend({ label, number }) {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" alignItems="center" spacing={1}>
        <Box
          sx={{
            width: 16,
            height: 16,
            bgcolor: "grey.50016",
            borderRadius: 0.75,
            ...(label === "Sold Online" && {
              bgcolor: "primary.main",
            }),
            ...(label === "Sold Offline" && {
              bgcolor: "secondary.main",
            }),
          }}
        />
        <Typography variant="subtitle2" sx={{ color: "text.secondary" }}>
          {label}
        </Typography>
      </Stack>
      <Typography variant="subtitle1">{number} Tickets</Typography>
    </Stack>
  );
}

export default function BookingRoomAvailable({ data, info }) {
  const theme = useTheme();
  const chartOptions = merge(BaseOptionChart(), {
    legend: { show: false },
    grid: {
      padding: { top: -32, bottom: -32 },
    },
    fill: {
      type: "gradient",
      gradient: {
        colorStops: [
          [
            { offset: 0, color: theme.palette.primary.main },
            { offset: 50, color: theme.palette.secondary.main },
          ],
        ],
      },
    },
    plotOptions: {
      radialBar: {
        hollow: { size: "64%" },
        dataLabels: {
          name: { offsetY: -16 },
          value: { offsetY: 8 },
          total: {
            label: "Total Tickets",
            formatter: () => fNumber(data?.totalQuantity),
          },
        },
      },
    },
  });

  return (
    <Card>
      <CardHeader
        subheader={`Price: €${data?.price}`}
        title={`Ticket: ${data?.name}`}
        sx={{ mb: 8 }}
      />
      <ReactApexChart
        type="radialBar"
        series={[((data.sold + data.gratis) / data.totalQuantity) * 100]}
        options={chartOptions}
        height={310}
      />

      <Stack spacing={2} sx={{ p: 5 }}>
        <Legend label="Sold Online" number={data.sold} />
        <Legend label="Sold Offline" number={data?.gratis} />
        <Legend
          label="Available"
          number={data?.totalQuantity - (data?.sold + data?.gratis)}
        />
      </Stack>
    </Card>
  );
}
