import { reject } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";

// ----------------------------------------------------------------------

const DIGITAL_OCEAN_TOKEN = process.env.REACT_APP_DIGITAL_OCEAN_TOKEN;

const initialState = {
  isLoading: false,
  error: false,
  events: [],
  event: null,
  bookings: [],
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null,
  },
};

const slice = createSlice({
  name: "event",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getEventsSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload;
      state.event = null;
    },

    // GET EVENT
    getEventSuccess(state, action) {
      state.isLoading = false;
      state.event = action.payload;
    },

    // GET BOOKINGS
    getBookingsSuccess(state, action) {
      state.isLoading = false;
      state.bookings = action.payload;
    },

    // GET BOOKINGS
    createEventSuccess(state, action) {
      state.isLoading = false;
      state.event = action.payload;
    },

    // DELETE PRODUCT
    deleteEventSuccess(state, action) {
      state.isLoading = false;
    },

    // GET CITIES
    getCitiesSuccess(state, action) {
      state.isLoading = false;
      state.cities = action.payload;
    },

    getTicketsSuccess(state, action) {
      state.isLoading = false;
      state.userTickets = action.payload;
    },

    clearEvent(state) {
      state.event = null;
    },

    deleteItineraryStatus(state, status) {
      state.itineraryDeleted = status;
    },

    deleteInclusionStatus(state, status) {
      state.inclusionDeleted = status;
    },

    createCitySuccess(state, status) {
      state.cityCreated = status;
    },

    reserveTicketsSuccess(state, status) {
      state.ticketsReserved = status;
    },
    getCouponsSuccess(state, action) {
      state.isLoading = false;
      state.coupons = action.payload;
    },
    createCouponSuccess(state, status) {
      state.isLoading = false;
      state.couponCreated = status;
    },
    deleteCouponSuccess(state, status) {
      state.isLoading = false;
      state.couponRemoved = status;
    },
    getAllTicketsSuccess(state, action) {
      state.isLoading = false;
      state.tickets = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getEvents() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/event/get");
      dispatch(slice.actions.getEventsSuccess(response.data.reverse()));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getEvent(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.clearEvent());
    dispatch(slice.actions.hasError(false));

    try {
      const response = await axios.get(`/api/event/gebyid/${id}`);
      dispatch(slice.actions.getEventSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getBookings() {
  return async (dispatch) => {
    dispatch(slice.actions.hasError(null));
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/statistics/getallinvoices");
      console.log(response);
      dispatch(slice.actions.getBookingsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createEvent(eventData) {
  return async (dispatch) => {
    dispatch(slice.actions.hasError(null));
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/api/event/add", eventData);
      dispatch(slice.actions.createEventSuccess(response?.data));
      triggerPipeline();
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateEvent(eventData, id) {
  return async (dispatch) => {
    dispatch(slice.actions.hasError(null));
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.patch(
        `/api/event/updateevent/${id}`,
        eventData
      );
      dispatch(slice.actions.createEventSuccess(response.data));
      triggerPipeline();
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTicketsByUser(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/getticketsbyuser`, {
        params: { id },
      });
      dispatch(slice.actions.getTicketsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function deleteItinerary(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/event/deleteitinerary/${id}`);
      dispatch(slice.actions.deleteItineraryStatus(response.status == 200));
      triggerPipeline();
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.deleteItineraryStatus(false));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteInclusion(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/event/deleteinclusion/${id}`);
      dispatch(slice.actions.deleteInclusionStatus(response.status == 200));
      triggerPipeline();
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.deleteInclusionStatus(false));
    }
  };
}

// ----------------------------------------------------------------------

export function getCities() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/city/get");
      dispatch(slice.actions.getCitiesSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createCity(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/api/city/add", data);
      dispatch(slice.actions.createCitySuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function reserveTickets(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        "/api/purchase/CreateGratisTicket",
        data
      );
      dispatch(slice.actions.reserveTicketsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAllCoupons() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/coupon/get");
      dispatch(slice.actions.getCouponsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createCoupon(data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/api/coupon/add", data);
      dispatch(slice.actions.createCouponSuccess(response.status === 200));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function removeCoupon(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/coupon/delete/${id}`);
      dispatch(slice.actions.deleteCouponSuccess(response.status === 200));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAllTickets() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/statistics/getalltickets");
      dispatch(slice.actions.getAllTicketsSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function triggerPipeline() {
  fetch(
    `https://api.digitalocean.com/v2/apps/${process.env.REACT_APP_WEB_ID}/deployments`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${DIGITAL_OCEAN_TOKEN}`,
      },
      body: JSON.stringify({
        force_build: true,
      }),
    }
  );
}

// ----------------------------------------------------------------------

export function deleteEvent(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/api/event/deleteevent/${id}`);
      dispatch(slice.actions.deleteEventSuccess(response.status === 200));
      dispatch(getEvents());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
