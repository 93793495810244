import baseService from "./baseService";
import axiosInstance from "../utils/axios";

const STRAPI_ENDPOINT = "/api";

export const uploadImage = (queryData) =>
  baseService.post(`${STRAPI_ENDPOINT}/upload`, queryData);

export const getAllImages = () =>
  baseService.get(`${STRAPI_ENDPOINT}/upload/files`);

export const getEventStats = (id) =>
  axiosInstance.get(`/api/statistics/geteventstatistic/${id}`);

export const getEventInfo = (id) =>
  axiosInstance.get(`/api/event/gebyid/${id}`);
