import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
  mailList: null,
  isLoading: false,
};

const slice = createSlice({
  name: "newsletter",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getMailListSuccess(state, action) {
      state.mailList = action.payload;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default slice.reducer;

export function getMailList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/newsletter");
      dispatch(slice.actions.getMailListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
