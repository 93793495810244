// ----------------------------------------------------------------------
import React from 'react';
export default function Snackbar() {
  return {
    MuiSnackbarContent: {
      styleOverrides: {
        root: {}
      }
    }
  };
}
