// routes
import { PATH_DASHBOARD } from "../../routes/paths";

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "events management",
    items: [
      { title: "bookings", path: PATH_DASHBOARD.event.bookings },
      { title: "events", path: PATH_DASHBOARD.event.list },
      { title: "coupons", path: PATH_DASHBOARD.event.coupons },
      { title: "newsletter list", path: PATH_DASHBOARD.event.newsletter },
    ],
  },
];

export default sidebarConfig;
