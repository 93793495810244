// material
import { styled } from "@mui/material/styles";
import { Card, Typography, Stack } from "@mui/material";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  padding: theme.spacing(3),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter,
}));

// ----------------------------------------------------------------------

export default function BookingTotalIncomes({ data }) {
  return (
    <RootStyle>
      <Stack direction="row" justifyContent="space-between" sx={{ mb: 3 }}>
        <div>
          <Typography sx={{ mb: 2, typography: "subtitle2" }}>
            Total Incomes For Event
          </Typography>
          <Typography sx={{ typography: "h3" }}>{data.salesValue} €</Typography>
        </div>
      </Stack>
    </RootStyle>
  );
}
