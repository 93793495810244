import { useEffect, useState } from "react";
// material
import { Grid, Container } from "@mui/material";
// hooks
import useSettings from "../../hooks/useSettings";
// components
import Page from "../../components/Page";
import {
  BookingTotal,
  BookingBookedRoom,
  BookingTotalIncomes,
  BookingRoomAvailable,
  BookingCheckInWidgets,
} from "../../components/_dashboard/general-booking";
import { LinearProgress } from "@mui/material";
import { getEventStats, getEventInfo } from "../../services/eventServices";
import { useParams } from "react-router-dom";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import { fDate } from "../../utils/formatTime";

// ----------------------------------------------------------------------

export default function GeneralBooking() {
  const { themeStretch } = useSettings();
  const id = useParams();

  const [eventInfo, setEventInfo] = useState(null);
  const [event, setEvent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getEventStatistics = async () => {
    try {
      const eventData = await getEventStats(id.id);
      setEventInfo(eventData.data.value);
    } catch (error) {
      console.log(error);
    }
  };

  const getEvent = async () => {
    try {
      const eventData = await getEventInfo(id.id);
      setEvent(eventData.data[0]);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEventStatistics();
    getEvent();
  }, []);

  useEffect(() => {
    if (event && eventInfo) {
      setIsLoading(false);
    }
  }, [event, eventInfo]);

  console.log(eventInfo);
  return (
    <Page title="Event Info">
      <Container maxWidth={themeStretch ? false : "xl"}>
        <HeaderBreadcrumbs
          heading={`Event: ${event?.name} - ${event?.location} (${
            event?.startDate ? fDate(event?.startDate) : null
          })`}
          links={[{ name: "" }]}
        />
        {isLoading ? (
          <LinearProgress />
        ) : (
          <Grid container spacing={3}>
            {event?.ticketTypes?.map((ticket) => (
              <Grid key={ticket.id} item xs={12} md={4}>
                <BookingRoomAvailable
                  info={ticket}
                  data={eventInfo?.ticketTypes?.find(
                    (item) => item.id === ticket.id
                  )}
                />
              </Grid>
            ))}

            <Grid item xs={12}>
              <BookingCheckInWidgets data={eventInfo} />
            </Grid>

            <Grid item xs={12} md={8}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <BookingTotalIncomes data={eventInfo} />
                </Grid>

                {/*  <Grid item xs={12} md={6}>
                  <BookingBookedRoom />
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Container>
    </Page>
  );
}
