import React from "react";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";

// material
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Container,
  TableContainer,
} from "@mui/material";
// redux
import { useDispatch, useSelector } from "../../redux/store";
import { getMailList } from "../../redux/slices/newsletter";
// hooks
import useSettings from "../../hooks/useSettings";
// components
import Page from "../../components/Page";
import Label from "../../components/Label";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import { ProductListHead } from "../../components/_dashboard/e-commerce/product-list";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "mail", label: "Mail", alignRight: false },

  { id: "status", label: "Status", alignRight: false },
];

// ----------------------------------------------------------------------

export default function EventList() {
  const { themeStretch } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("createdAt");
  //global state
  const mailList = useSelector((state) => state.newsletter?.mailList);

  useEffect(() => {
    dispatch(getMailList());
  }, [dispatch]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Page title="Boom - Newsletter List">
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs
          heading="Subscribers List"
          links={[{ name: "" }]}
          action={[]}
        />
        <Card>
          <TableContainer sx={{ minWidth: 800, maxHeight: "750px" }}>
            <Table>
              <ProductListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={mailList?.length}
                onRequestSort={handleRequestSort}
              />

              <TableBody>
                {mailList &&
                  mailList.map((row) => (
                    <TableRow>
                      <TableCell key={row.id}>{row.email}</TableCell>
                      <TableCell key={row.id}>
                        {row.subscription ? (
                          <Label color="success">subscribed</Label>
                        ) : (
                          <Label color="error">unsubscribed</Label>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Container>
    </Page>
  );
}
