import axios from "axios";
import { setSession } from "./jwt";
import { original } from "@reduxjs/toolkit";

const BASE_URL = process.env.REACT_APP_BACKEND_API;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  },
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  },
  withCredentials: true,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401) {
      if (originalRequest.url.includes("api/auth/refreshtoken/")) {
        return Promise.reject(error);
      }

      if (!originalRequest._retry) {
        originalRequest._retry = true;
        const refreshToken = localStorage.getItem("refreshToken");
        return axiosInstance
          .post(`/api/auth/refreshtoken?refreshToken=${refreshToken}`)
          .then(async (res) => {
            await setSession(res.data.token, res.data.refreshToken);
            originalRequest.headers.Authorization = `Bearer ${res.data.token}`;
            return axiosInstance.request(originalRequest);
          });
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
