// routes
import Router from "./routes";
import useAuth from "./hooks/useAuth";

// theme
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
import LoadingScreen, { ProgressBarStyle } from "./components/LoadingScreen";
import { SnackbarProvider } from "notistack";

function App() {
  const { isInitialized } = useAuth();
  return (
    <ThemeConfig>
      <GlobalStyles />
      <SnackbarProvider>
        {isInitialized ? <Router /> : <LoadingScreen />}
      </SnackbarProvider>
    </ThemeConfig>
  );
}

export default App;
