import {
  Card,
  Modal,
  Stack,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import { Container } from "@mui/system";
import { Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import Page from "../../components/Page";
import useSettings from "../../hooks/useSettings";
import { Add, Delete } from "@mui/icons-material";
import { ProductListHead } from "../../components/_dashboard/e-commerce/product-list";
import {
  createCoupon,
  getAllCoupons,
  removeCoupon,
} from "../../redux/slices/event";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

const TABLE_HEAD = [
  { id: "couponCode", label: "Coupon", alignRight: false },
  {
    id: "discountPercentage",
    label: "Discount Percentage(%)",
    alignRight: false,
  },
  { id: "validUntil", label: "Valid Until", alignRight: false },
  { id: "actions" },
];

function CouponsList() {
  const { themeStretch } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({});

  //global state
  const couponCreated = useSelector((state) => state.event.couponCreated);
  const couponRemoved = useSelector((state) => state.event.couponRemoved);

  const coupons = useSelector((state) => state.event.coupons);

  useEffect(() => {
    if (couponRemoved) {
      enqueueSnackbar("Coupon Removed!");
      dispatch(getAllCoupons());
    }
  }, [couponRemoved]);

  useEffect(() => {
    setModalInfo({});
    setModalOpen(false);
    dispatch(getAllCoupons());
  }, [couponCreated]);

  const handleInputChange = (event) => {
    const { value, name, type } = event.target;
    setModalInfo({
      ...modalInfo,
      [name]: type === "number" ? parseInt(value) : value,
    });
  };

  const handleSubmit = () => {
    dispatch(createCoupon(modalInfo));
  };

  return (
    <Page title="Boom - Coupon List">
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            p: 2,
            minWidth: "350px",
          }}
        >
          <Typography gutterBottom variant="h6">
            Create Coupon:
          </Typography>
          <Stack spacing={2}>
            <TextField
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={modalInfo.couponCode ?? ""}
              name="couponCode"
              label="Code"
            ></TextField>
            <TextField
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={modalInfo.discountPercentage ?? ""}
              name="discountPercentage"
              label="Discount Percentage"
            ></TextField>
            <TextField
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
              fullWidth
              value={modalInfo.validUntilDate ?? ""}
              name="validUntilDate"
              label="Valid Until"
              type="date"
            ></TextField>
            <Button onClick={handleSubmit} variant="contained">
              Create Code
            </Button>
          </Stack>
        </Card>
      </Modal>
      <Container maxWidth={themeStretch ? false : "lg"}>
        <HeaderBreadcrumbs
          heading="Coupons List"
          links={[{ name: "" }]}
          action={
            <Button
              variant="contained"
              startIcon={<Add />}
              onClick={() => setModalOpen(true)}
            >
              Create new coupon
            </Button>
          }
        />
        <Card>
          <Table>
            <ProductListHead headLabel={TABLE_HEAD} />
            {coupons &&
              coupons.map((coupon) => (
                <TableRow hover key={coupon.id} tabIndex={-1} role="checkbox">
                  <TableCell component="th" scope="row">
                    <Typography variant="subtitle2" noWrap>
                      {coupon.couponCode}
                    </Typography>
                  </TableCell>
                  <TableCell>{coupon.discountPercentage}</TableCell>
                  <TableCell>{coupon.validUntilDate}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => dispatch(removeCoupon(coupon.id))}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </Table>
        </Card>
      </Container>
    </Page>
  );
}

export default CouponsList;
