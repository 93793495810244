import React from "react";
import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import MainLayout from "../layouts/main";
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from "../components/LoadingScreen";
import CouponsList from "../pages/dashboard/CouponsList";
import EventInfo from "../pages/dashboard/EventInfo";
import NewsletterList from "../pages/dashboard/NewsletterList";

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks

  const isDashboard = true;

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: "login-unprotected", element: <Login /> },
        { path: "reset-password", element: <ResetPassword /> },
      ],
    },
    // Dashboard Routes
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: "", element: <Navigate to="/dashboard/event/list" replace /> },
        { path: "coupons", element: <CouponsList /> },
        { path: "newsletter", element: <NewsletterList /> },

        {
          path: "event",
          children: [
            { element: <Navigate to="/dashboard/event/list" replace /> },
            { path: "list", element: <EventList /> },
            { path: "bookings", element: <EventBookingList /> },
            { path: "new", element: <CreateEvent /> },
            { path: ":id/edit", element: <CreateEvent /> },
            { path: ":id/duplicate", element: <CreateEvent /> },
            { path: ":id", element: <EventInfo /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: "/",
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import("../pages/authentication/Login")));
const Register = Loadable(
  lazy(() => import("../pages/authentication/Register"))
);
const ResetPassword = Loadable(
  lazy(() => import("../pages/authentication/ResetPassword"))
);
const VerifyCode = Loadable(
  lazy(() => import("../pages/authentication/VerifyCode"))
);

// Dashboard
const EventList = Loadable(lazy(() => import("../pages/dashboard/EventList")));
const CreateEvent = Loadable(
  lazy(() => import("../pages/dashboard/CreateEvent"))
);
const EventBookingList = Loadable(
  lazy(() => import("../pages/dashboard/EventBookingList"))
);

const NotFound = Loadable(lazy(() => import("../pages/Page404")));
