import { merge } from "lodash";
import ReactApexChart from "react-apexcharts";
// material
import { useTheme } from "@mui/material/styles";
import { Card, Typography, Stack, Divider, useMediaQuery } from "@mui/material";
// utils
import { fNumber } from "../../../utils/formatNumber";
import { BaseOptionChart } from "../../charts";

// ----------------------------------------------------------------------

const CHART_SIZE = { width: 116, height: 116 };

export default function BookingCheckInWidgets(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const chartOptionsCheckIn = merge(BaseOptionChart(), {
    colors: [theme.palette.chart.blue[0]],
    chart: { sparkline: { enabled: true } },
    grid: {
      padding: {
        top: -9,
        bottom: -9,
      },
    },
    legend: { show: false },
    plotOptions: {
      radialBar: {
        hollow: { size: "64%" },
        track: { margin: 0 },
        dataLabels: {
          name: { show: false },
          value: {
            offsetY: 6,
            fontSize: theme.typography.subtitle2.fontSize,
          },
        },
      },
    },
  });

  const chartOptionsCheckOut = {
    ...chartOptionsCheckIn,
    colors: [theme.palette.chart.violet[2]],
  };

  const chartOptionsOther = {
    ...chartOptionsCheckIn,
    colors: [theme.palette.chart.yellow[0]],
  };
  const other = props?.data?.sold - (props?.data?.female + props?.data?.male);

  return (
    <Card>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        divider={
          <Divider
            orientation={isMobile ? "horizontal" : "vertical"}
            flexItem
          />
        }
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={3}
          sx={{ width: 1, py: 5 }}
        >
          <ReactApexChart
            type="radialBar"
            series={
              [((props?.data?.male / props?.data?.sold) * 100).toFixed(1)] ?? []
            }
            options={chartOptionsCheckIn}
            {...CHART_SIZE}
          />
          <div>
            <Typography variant="h4" sx={{ mb: 0.5 }}>
              {fNumber(props?.data?.male)}
            </Typography>
            <Typography variant="body2" sx={{ opacity: 0.72 }}>
              Male
            </Typography>
          </div>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={3}
          sx={{ width: 1, py: 5 }}
        >
          <ReactApexChart
            type="radialBar"
            series={
              [((props?.data?.female / props?.data?.sold) * 100).toFixed(1)] ??
              []
            }
            options={chartOptionsCheckOut}
            {...CHART_SIZE}
          />
          <div>
            <Typography variant="h4" sx={{ mb: 0.5 }}>
              {fNumber(props?.data?.female)}
            </Typography>
            <Typography variant="body2" sx={{ opacity: 0.72 }}>
              Female
            </Typography>
          </div>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={3}
          sx={{ width: 1, py: 5 }}
        >
          <ReactApexChart
            type="radialBar"
            series={[((other / props?.data?.sold) * 100).toFixed(1)] ?? []}
            options={chartOptionsOther}
            {...CHART_SIZE}
          />
          <div>
            <Typography variant="h4" sx={{ mb: 0.5 }}>
              {fNumber(other)}
            </Typography>
            <Typography variant="body2" sx={{ opacity: 0.72 }}>
              Other
            </Typography>
          </div>
        </Stack>
      </Stack>
    </Card>
  );
}
