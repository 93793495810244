import PropTypes from 'prop-types';
// material
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	sx: PropTypes.object
};

export default function Logo({ sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	return (
		<Box sx={{ width: 90, height: 40, ...sx }}>

			<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1080 407" style={{ enableBackground: "new 0 0 980 397" }}>
				<g>
					<g>
						<path d="M33,223.5V38.3h107.4c9.2,0,17.5,2,25,6.1c7.5,4.1,13.5,9.7,18,16.9c4.5,7.2,6.7,15.8,6.7,25.7c0,5.8-1.1,11.4-3.3,16.7
			c-2.2,5.3-5.3,10-9.3,14c-4,4.1-8.5,7.3-13.6,9.8c6,2.5,11.3,6,16,10.6c4.7,4.6,8.4,9.9,11.1,15.9c2.7,6,4.1,12.4,4.1,19.3
			c0,10.1-2.3,18.9-7,26.5c-4.7,7.6-10.9,13.5-18.7,17.6c-7.8,4.1-16.4,6.2-25.9,6.2H33z M77.4,112.1h50c3.5,0,6.7-0.8,9.4-2.5
			c2.7-1.7,4.9-3.9,6.5-6.6c1.6-2.7,2.4-5.8,2.4-9.1c0-3.5-0.8-6.7-2.4-9.4c-1.6-2.7-3.8-4.9-6.5-6.6c-2.7-1.7-5.9-2.5-9.4-2.5h-50
			V112.1z M77.4,186.7H132c3.7,0,7-0.9,9.9-2.6c2.9-1.8,5.2-4.1,7-7.1c1.8-3,2.6-6.3,2.6-9.8c0-3.7-0.9-7-2.6-9.9
			c-1.8-2.9-4.1-5.2-7-7c-2.9-1.8-6.2-2.6-9.9-2.6H77.4V186.7z"/>
						<g>
							<path d="M52.8,331.5c-3.6,0-6.9-0.8-9.8-2.5c-2.9-1.7-5.3-4-7-6.8c-1.7-2.8-2.6-6.1-2.6-9.7c0-2.7,0.6-5.2,1.8-7.6
				c1.2-2.4,2.8-4.4,4.8-6.1c-2-1.7-3.5-3.6-4.6-5.9c-1.1-2.3-1.7-4.7-1.7-7.2c0-3.5,0.9-6.7,2.6-9.5c1.7-2.8,4-5,6.9-6.6
				c2.9-1.6,6.1-2.4,9.6-2.4h27.1v13L55,280.1c-1.2,0-2.2,0.3-3.2,0.8c-1,0.6-1.8,1.3-2.3,2.2c-0.6,0.9-0.9,2-0.9,3.2
				c0,1.2,0.3,2.2,0.9,3.2c0.6,1,1.4,1.7,2.3,2.2c1,0.5,2.1,0.8,3.2,0.8h21.6v12.9H55.5c-1.3,0-2.5,0.3-3.5,0.9
				c-1,0.6-1.9,1.4-2.5,2.4c-0.6,1-0.9,2.1-0.9,3.4c0,1.2,0.3,2.3,0.9,3.4c0.6,1,1.4,1.8,2.5,2.3c1,0.6,2.2,0.8,3.5,0.8h24.3v13
				H52.8z"/>
							<path d="M134.5,332.6c-3,0-5.7-0.7-8.1-2.1c-2.4-1.4-4.1-3.5-5.1-6.5l-19.6-56.9h16.6l14.9,49.8c0.1,0.4,0.3,0.6,0.5,0.8
				c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.4-0.5,0.5-0.8l15.2-49.8h16.3l-19.5,56.9c-1,2.9-2.8,5-5.1,6.4
				C140.2,331.9,137.5,332.6,134.5,332.6z"/>
							<path d="M208.3,331.5c-3.6,0-6.9-0.8-9.8-2.5c-2.9-1.7-5.3-4-7-6.8c-1.7-2.8-2.6-6.1-2.6-9.7c0-2.7,0.6-5.2,1.8-7.6
				c1.2-2.4,2.8-4.4,4.8-6.1c-2-1.7-3.5-3.6-4.6-5.9c-1.1-2.3-1.7-4.7-1.7-7.2c0-3.5,0.9-6.7,2.6-9.5c1.7-2.8,4-5,6.9-6.6
				c2.9-1.6,6.1-2.4,9.6-2.4h27.1v13l-24.7-0.1c-1.2,0-2.2,0.3-3.2,0.8c-1,0.6-1.8,1.3-2.3,2.2s-0.9,2-0.9,3.2
				c0,1.2,0.3,2.2,0.9,3.2c0.6,1,1.4,1.7,2.3,2.2c1,0.5,2.1,0.8,3.2,0.8h21.6v12.9H211c-1.3,0-2.5,0.3-3.5,0.9
				c-1,0.6-1.9,1.4-2.5,2.4c-0.6,1-0.9,2.1-0.9,3.4c0,1.2,0.3,2.3,0.9,3.4c0.6,1,1.4,1.8,2.5,2.3c1,0.6,2.2,0.8,3.5,0.8h24.3v13
				H208.3z"/>
							<path d="M308.1,332.6c-3.9,0-7.3-0.8-10.4-2.5c-3.1-1.7-5.5-4-7.3-7c-1.8-3-2.7-6.4-2.7-10.2v-28.1c0-1-0.2-1.9-0.7-2.7
				c-0.5-0.8-1.1-1.4-1.9-1.9c-0.8-0.5-1.7-0.7-2.7-0.7s-1.9,0.2-2.7,0.7c-0.8,0.5-1.4,1.1-1.9,1.9c-0.5,0.8-0.7,1.7-0.7,2.7v46.8
				h-15.1v-45.7c0-3.9,0.9-7.3,2.6-10.2c1.7-2.9,4.2-5.3,7.3-7c3.1-1.7,6.6-2.6,10.4-2.6c3.9,0,7.4,0.9,10.5,2.6
				c3.1,1.7,5.5,4,7.3,7s2.7,6.3,2.7,10.2V314c0,1,0.2,1.9,0.7,2.7c0.5,0.8,1.1,1.5,1.9,1.9c0.8,0.5,1.6,0.7,2.5,0.7
				c1,0,1.9-0.2,2.7-0.7c0.8-0.5,1.5-1.1,2-1.9c0.5-0.8,0.7-1.7,0.7-2.7v-46.8h15v45.7c0,3.8-0.9,7.2-2.7,10.2c-1.8,3-4.2,5.3-7.3,7
				C315.4,331.8,311.9,332.6,308.1,332.6z"/>
							<path d="M390.5,331.5c-3.9,0-7.4-0.9-10.3-2.6c-3-1.7-5.3-4.1-7-7c-1.7-2.9-2.5-6.2-2.5-9.8v-32h-17.5v-12.9h50.3v12.9h-17.4
				v33.3c0,1.4,0.5,2.6,1.5,3.7c1,1,2.2,1.6,3.6,1.6h5.1v12.9H390.5z"/>
							<path d="M429.2,331.5v-12.9h27.5c1.2,0,2.3-0.3,3.3-0.9c1-0.6,1.8-1.4,2.3-2.4c0.6-1,0.9-2,0.9-3.2c0-1.2-0.3-2.3-0.9-3.3
				c-0.6-1-1.4-1.8-2.3-2.4c-1-0.6-2.1-0.9-3.3-0.9h-9.9c-3.7,0-7.1-0.8-10.2-2.3c-3.1-1.5-5.5-3.7-7.3-6.5
				c-1.8-2.8-2.7-6.2-2.7-10.2c0-3.9,0.9-7.3,2.6-10.2s4-5.1,7-6.8c2.9-1.6,6.2-2.4,9.7-2.4h28.4v12.9h-26.6c-1.1,0-2.1,0.3-3,0.8
				c-0.9,0.6-1.6,1.3-2.1,2.2s-0.7,1.9-0.7,3c0,1.1,0.2,2.1,0.7,3c0.5,0.9,1.2,1.6,2.1,2.2c0.9,0.6,1.9,0.8,3,0.8h10.4
				c4.1,0,7.7,0.8,10.7,2.3c3,1.6,5.4,3.7,7.1,6.5c1.7,2.8,2.5,6.1,2.5,9.8c0,4.4-0.9,8.2-2.6,11.3c-1.7,3.1-4.1,5.5-7,7.1
				s-6.2,2.4-9.7,2.4H429.2z"/>
						</g>
						<path d="M921.2,206.7c15.8-3.5,27.1-6.5,37.6-10.4c-1.3-2.4-2.9-5-4.9-7.9h-25.2c-2.8,0-5.4-0.7-7.7-2.1c-2.3-1.4-4.1-3.2-5.4-5.5
			c-1.3-2.3-2-4.9-2-7.7V92c0-11.1-2.6-20.9-7.7-29.4c-5.1-8.5-12.1-15.2-20.8-20.1c-2.9-1.6-5.9-3-9-4c-6.4-2.2-13.4-3.4-21-3.4
			c-11,0-20.9,2.5-29.8,7.4c-8.9,4.9-15.9,11.6-21.1,20.1c-5.1,8.5-7.6,18.3-7.6,29.4v80.7c0,3-0.7,5.7-2.1,7.9
			c-1.4,2.3-3.3,4.1-5.7,5.4c-2.4,1.3-5,2-7.8,2c-2.6,0-5.1-0.7-7.3-2c-2.2-1.3-4-3.1-5.4-5.4c-1.4-2.3-2.1-4.9-2.1-7.9V92
			c0-11.1-2.6-20.9-7.7-29.4c-5.1-8.5-12.1-15.2-20.9-20.1c-8.8-4.9-18.9-7.4-30.2-7.4c-11.1,0-21.1,2.5-30,7.4
			c-8.9,4.9-15.9,11.6-20.9,20.1c-5,8.5-7.5,18.3-7.5,29.4v233.5c0,2.8-0.7,5.4-2,7.7c-1.3,2.3-3.1,4.1-5.4,5.5
			c-2.3,1.4-4.9,2.1-7.6,2.1c-2.8,0-5.4-0.7-7.7-2.1c-2.3-1.4-4.2-3.3-5.5-5.5c-1.4-2.3-2.1-4.9-2.1-7.7v-80.7
			c0-11.6-2.6-21.7-7.8-30.2c-2-3.2-4.3-6.2-6.9-8.9l21.7-16.1l-15.3-20.5l-0.1,0.1c5-11.6,7.8-24.5,7.8-38
			c0-53-43.1-96.2-96.1-96.2c-53,0-96.2,43.1-96.2,96.2s43.1,96.2,96.2,96.2c3.6,0,7.1-0.2,10.6-0.6l-0.1,0.1l15.2,20.6l22.8-16.9
			c1,0.9,1.9,1.9,2.6,3.2c1.4,2.3,2.1,4.9,2.1,7.9v80.7c0,11.1,2.6,20.9,7.7,29.4c5.1,8.5,12.1,15.1,20.9,20.1
			c8.8,4.9,18.9,7.4,30.2,7.4c11.1,0,21.1-2.5,30-7.4c8.9-5,15.9-11.6,20.9-20.1c5-8.5,7.5-18.3,7.5-29.4V88.8c0-2.8,0.7-5.4,2-7.7
			c1.3-2.3,3.1-4.2,5.4-5.5c2.3-1.4,4.9-2.1,7.7-2.1c2.8,0,5.4,0.7,7.7,2.1c2.3,1.4,4.2,3.3,5.5,5.5c1.4,2.3,2.1,4.9,2.1,7.7v80.7
			c0,11.6,2.6,21.7,7.8,30.2c5.2,8.5,12.3,15.1,21.2,19.8c8.9,4.7,18.7,7.1,29.5,7.1c10.8,0,20.5-2.4,29.4-7.1
			c8.8-4.8,15.9-11.4,21.2-19.8c5.3-8.5,7.9-18.5,7.9-30.2v-81c0-2.8,0.7-5.3,2.1-7.5c1.4-2.2,3.3-4,5.7-5.4
			c2.4-1.4,4.9-2.1,7.6-2.1c2.8,0,5.4,0.7,7.6,2.1c2.3,1.4,4.1,3.2,5.4,5.4c1.3,2.2,2,4.7,2,7.5v81.4c0,11.1,2.5,20.9,7.6,29.4
			c5,8.5,12,15.2,20.9,20.1c2.9,1.7,6,3,9.2,4.1c6.5,2.2,13.4,3.3,20.8,3.3h25c2.4-3.5,4.3-6.6,5.7-9.5
			C948.8,213.4,937.4,210.2,921.2,206.7z M522.1,183.2c-28.7,0-52.1-23.4-52.1-52.1s23.3-52.1,52.1-52.1c28.7,0,52,23.4,52,52.1
			S550.8,183.2,522.1,183.2z"/>
						<path d="M978.1,188.5v38.4h-24.3c2.4-3.5,4.3-6.6,5.7-9.5c-10.6-4-22-7.1-38.2-10.6c15.8-3.5,27.1-6.5,37.6-10.4
			c-1.3-2.4-2.9-5-4.9-7.9H978.1z"/>
						<g>
							<path d="M310.3,226.8c-53,0-96.2-43.1-96.2-96.2s43.1-96.2,96.2-96.2s96.2,43.1,96.2,96.2S363.4,226.8,310.3,226.8z M310.3,78.6
				c-28.7,0-52.1,23.4-52.1,52.1c0,28.7,23.4,52.1,52.1,52.1c28.7,0,52.1-23.4,52.1-52.1C362.4,101.9,339,78.6,310.3,78.6z"/>
						</g>
					</g>
					<g>
						<path style={{fill:"#FF7101"}} d="M890.8,229.7c55.1-12.8,69.5,5.2,47.1,58.5c22.4-53.3,42.1-52.7,64.3,1.9
			c-22.1-54.6-10.1-68.2,39.1-44.3c-49.2-24-46.9-40.8,7.7-54.8c-54.6,14-63.1-5.2-28-62.5c-35.2,57.3-60.8,58.5-83.4,4.1
			c22.6,54.4,5.5,68.9-55.7,47.1C943.1,201.6,945.8,216.9,890.8,229.7z"/>
						<g>
							<path style={{fill:"#FFFFFF"}} d="M955,191.2c12.4,13.4,10,20.2-7.7,22c17.7-1.8,20.8,3.8,10.1,18.2c10.8-14.4,16.3-13.1,18.1,4.2
				c-1.8-17.4,3.1-19.3,15.7-6.1c-12.6-13.1-9-18.4,11.9-17.3c-20.9-1.2-25.4-8.1-14.8-22.7c-10.6,14.5-17.3,12-21.5-8.4
				C971,201.5,967.4,204.6,955,191.2z"/>
						</g>
					</g>
				</g>
			</svg>

		</Box>
	);
}
